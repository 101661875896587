import { graphql, StaticQuery } from 'gatsby';
import 'modern-normalize';
import * as React from 'react';
import Helmet from 'react-helmet';
import LayoutMain from '../components/LayoutMain';
import LayoutRoot from '../components/LayoutRoot';
import '../styles/normalize';

interface StaticQueryProps {
    site: {
        siteMetadata: {
            title: string;
            description: string;
        };
    };
}

const IndexLayout: React.FunctionComponent = ({ children }) => (
    <StaticQuery
        query={graphql`
            query IndexLayoutQuery {
                site {
                    siteMetadata {
                        title
                        description
                    }
                }
            }
        `}
    >
        {(data: StaticQueryProps) => (
            <LayoutRoot>
                <Helmet
                    title={data.site.siteMetadata.title}
                    meta={[
                        { name: 'description', content: data.site.siteMetadata.description },
                        { name: 'keywords', content: 'gatsbyjs, gatsby, javascript, sample, something' }
                    ]}
                />
                {/* <Navigation title={data.site.siteMetadata.title} /> */}
                <LayoutMain>{children}</LayoutMain>
            </LayoutRoot>
        )}
    </StaticQuery>
);

export default IndexLayout;
