import styled from '@emotion/styled';
import * as React from 'react';
import { dimensions } from '../styles/variables';

const StyledPage = styled.div<{ color?: string }>`
    display: block;
    flex: 1;
    position: relative;
    padding: ${dimensions.containerPadding}rem;
    width: 100%;
    background: ${({ color }) => color || 'white'};
`;

interface PageProps {
    className?: string;
    color?: string;
}

const Page: React.FunctionComponent<PageProps> = ({ children, className, color }) => (
    <StyledPage color={color} className={className}>
        {children}
    </StyledPage>
);

export default Page;
